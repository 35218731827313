import resizeImage from "shared/service/utils/Compressor";
import {
  FullMetadata,
  UploadResult,
  deleteObject,
  getDownloadURL,
  getMetadata,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import { FIREBASE_AUTH, FIREBASE_STORAGE, LOGGER } from "shared/Firebase";
import { XStatus } from "shared/service/utils/XStatus";
import { UploadFileInput } from "shared/API";

export async function getFileURL(fullPath: string): Promise<string> {
  const fileRef = ref(FIREBASE_STORAGE!, fullPath);
  return getDownloadURL(fileRef);
}

async function saveFile(input: UploadFileInput): Promise<UploadResult> {
  const user = FIREBASE_AUTH!.currentUser;
  if (!user) {
    throw XStatus.NoSignedInUser;
  }
  const storageRef = ref(
    FIREBASE_STORAGE!,
    `${input.directoryName}/${input.fileName}`,
  );
  return uploadBytes(storageRef, input.file, input.metadata);
}

async function deleteFile(fullPath: string): Promise<void> {
  const contentRef = ref(FIREBASE_STORAGE!, fullPath);
  return deleteObject(contentRef);
}

export async function saveImage(
  input: UploadFileInput & { testMode?: boolean },
): Promise<UploadResult> {
  if (input.testMode || input.file instanceof Uint8Array) {
    return saveFile(input);
  }
  // TODO(MR): Pick the most suitable client-side to resize images
  input.file = await resizeImage(input.file);
  return saveFile(input);
}

export async function deleteImage(
  directory: string,
  filename: string,
): Promise<void> {
  return deleteFile(`${directory}/${filename}`);
}

export async function getImage(
  directory: string,
  filename: string,
): Promise<string> {
  return getFileURL(`${directory}/${filename}`);
}

export async function getFileMetadata(
  directory: string,
  fileName: string,
): Promise<FullMetadata> {
  const fileRef = ref(FIREBASE_STORAGE!, `${directory}/${fileName}`);
  return getMetadata(fileRef);
}

export async function listAllFiles(directory: string): Promise<string[]> {
  const listRef = ref(FIREBASE_STORAGE!, directory);
  return listAll(listRef)
    .then(async (res) => {
      const urls: string[] = [];
      for (const itemRef of res.items) {
        urls.push(await getDownloadURL(itemRef));
      }
      return urls;
    })
    .catch((err) => {
      LOGGER?.error("error in listing all files:", err);
      return [];
    });
}
