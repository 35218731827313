import { Box, SxProps } from "@mui/material";

interface IXTagProps {
  text: string;
  isFilled?: boolean;
}

export const XTag = (props: IXTagProps) => {
  const sxFilled: SxProps = {
    background: "rgba(255, 58, 63, 0.1)",
    border: "1px solid transparent",
  };
  const sx: SxProps = {
    flex: 0,
    border: "1px solid rgba(135, 135, 135, 0.1)",
    p: 1,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "12px",
    textAlign: "center",
  };
  return <Box sx={[sx, props.isFilled ? sxFilled : {}]}>{props.text}</Box>;
};
