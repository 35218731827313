import { zodResolver } from "@hookform/resolvers/zod";
import { Box } from "@mui/material";
import Page from "components/Page";
import XButton from "components/common/XButton";
import XTextField from "components/common/XTextField";
import {
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import routes from "router/routes";
import { z } from "zod";

const schema = z.object({
  email: z.string().email(),
  password: z.string(),
});

type FormFields = z.infer<typeof schema>;

export const Login = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [authing, setAuthing] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormFields>({
    resolver: zodResolver(schema),
  });

  const signInWithGoogle = async () => {
    setAuthing(true);

    signInWithPopup(auth, new GoogleAuthProvider())
      .then((response) => {
        console.log(response.user.uid);
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        setAuthing(false);
      });
  };

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    console.log("====> data:", data);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password,
      );
      console.log("=====> userCredential", userCredential);
      onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log(user);
          navigate("/");
        } else {
          console.log("No user info found!");
        }
      });
    } catch (error) {
      console.log("=====> error", error);
      setError("email", { message: "Invalid credentials. Please try again" });
    }
  };

  return (
    <Page title="Login">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          alignItems={"flex-start"}
        >
          <XTextField
            register={register}
            errors={errors.email}
            name="email"
            label="Email"
          />
          <XTextField
            register={register}
            errors={errors.password}
            name="password"
            type="password"
            label="Password"
          />
          <XButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Loading..." : "Submit"}
          </XButton>
        </Box>
      </form>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        alignItems={"flex-start"}
        mt={6}
      >
        <XButton
          variant="secondary"
          onClick={() => signInWithGoogle()}
          disabled={authing}
        >
          Sign In With Google
        </XButton>
        <XButton variant="secondary" to={routes.SignUp.path} disabled={authing}>
          Sign Up with Email
        </XButton>
      </Box>
    </Page>
  );
};
