import { Box, Input, SxProps } from "@mui/material";

interface IXTextFieldProps {
  name: string;
  label: string;
  errors: any;
  helperText?: React.ReactNode;
  variant?: "filled" | "outlined" | "standard";
  multiline?: boolean;
  register?: any;
  type?: string;
}

const XTextField = (props: IXTextFieldProps) => {
  const sxFieldContainer: SxProps = {
    display: "flex",
    flexDirection: "column",
    gap: 1.5,
    width: "100%",
    maxWidth: "400px",
  };
  const sxTitle: SxProps = {
    width: "100%",
    color: "#ffffff",
    fontSize: 16,
    fontWeight: "400",
    lineHeight: "24px",
  };
  const sxField: SxProps = {
    width: "100%",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    py: 1,
    px: 2,
    fontSize: 20,
  };
  const sxErrors: SxProps = {
    width: "100%",
    color: "#ff0000",
    fontSize: 12,
  };

  return (
    <Box sx={sxFieldContainer}>
      <Box sx={sxTitle}>{props.label}</Box>
      <Input
        {...props.register(props.name)}
        name={props.name}
        sx={sxField}
        type={props.type}
      />
      {props.errors && <Box sx={sxErrors}>{props.errors.message}</Box>}
    </Box>
  );
};

export default XTextField;
