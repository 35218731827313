import firebase from "firebase/compat/app";
import firebaseConfig from "firebase-exports";
import {
  Firestore,
  connectFirestoreEmulator,
  getFirestore,
} from "firebase/firestore";
import {
  Auth,
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
import {
  FirebaseStorage,
  connectStorageEmulator,
  getStorage,
} from "firebase/storage";
import {
  getFunctions,
  connectFunctionsEmulator,
  Functions,
} from "firebase/functions";
import { Logger } from "@firebase/logger";
import { Analytics, getAnalytics, setUserId } from "firebase/analytics";

export let FIREBASE_DATABASE: Firestore | null = null;
export let FIREBASE_AUTH: Auth | null = null;
export let FIREBASE_STORAGE: FirebaseStorage | null = null;
export let FIREBASE_FUNCTIONS: Functions | null = null;
export let FIREBASE_ANALYTICS: Analytics | null = null;
export let FIREBASE_NAME: string | null = null;
export let LOGGER: Logger | null = null;

/**
 * Initialize Firebase services
 * @param {boolean} emulator Should it initialize the emulator?
 */
export function initializeFirebase(emulator?: boolean) {
  const app = firebase.initializeApp(firebaseConfig);
  FIREBASE_DATABASE = getFirestore(app);

  FIREBASE_AUTH = getAuth(app);
  FIREBASE_NAME = getAuth(app).currentUser?.displayName || "";
  FIREBASE_STORAGE = getStorage(app);
  FIREBASE_FUNCTIONS = getFunctions(app);
  LOGGER = new Logger("website");
  if (emulator) {
    connectFirestoreEmulator(FIREBASE_DATABASE, "127.0.0.1", 8080);
    connectAuthEmulator(FIREBASE_AUTH, "http://127.0.0.1:9099");
    connectStorageEmulator(FIREBASE_STORAGE, "127.0.0.1", 9199);
    connectFunctionsEmulator(FIREBASE_FUNCTIONS, "127.0.0.1", 5001);
  } else {
    FIREBASE_ANALYTICS = getAnalytics(app);
    onAuthStateChanged(FIREBASE_AUTH!, (user) => {
      if (!FIREBASE_ANALYTICS) {
        return;
      }
      if (user) {
        setUserId(FIREBASE_ANALYTICS, user.uid);
      } else {
        setUserId(FIREBASE_ANALYTICS, "public");
      }
    });
  }
}
