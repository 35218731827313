import theme from "styles/theme";
import { NavLink } from "react-router-dom";
import { Box, SxProps } from "@mui/material";
import routes from "router/routes";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { FIREBASE_AUTH } from "shared/Firebase";
import { useEffect, useState } from "react";

const Header = () => {
  // const { user, signOut } = useAuthenticator();

  // TODO: Mahdi to fix the background gradient
  document.body.style.backgroundPosition = "center";
  document.body.style.backgroundRepeat = "no-repeat";
  document.body.style.backgroundSize = "100%";

  const sx: SxProps = {
    display: "flex",
    alignItems: "center",
    px: 15,
    py: 4,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2,
    "& a": {
      color: theme.palette.text.secondary,
      textDecoration: "none",
      textTransform: "uppercase",
      ":hover": {
        color: "#cccccc",
        cursor: "pointer",
      },
    },
  };

  const sxLogo: SxProps = {
    borderRight: "1px solid #ffffff",
    pr: 6,
    mr: 6,
    "& img": {
      width: 68,
    },
  };
  const sxLeft: SxProps = {
    display: "flex",
    textAlign: "left",
    flex: 1,
    gap: 5,
  };
  const sxRight: SxProps = {
    textAlign: "right",
    width: 150,
  };

  const [currentUser, setCurrentUser] = useState(FIREBASE_AUTH?.currentUser);

  useEffect(() => {
    if (FIREBASE_AUTH) {
      onAuthStateChanged(FIREBASE_AUTH, (user) => {
        if (user) {
          setCurrentUser(user);
        } else {
          setCurrentUser(null);
          console.log("No user info found!");
        }
      });
    }
  }, []);

  console.log("FIREBASE_AUTH", FIREBASE_AUTH);
  console.log("FIREBASE_AUTH.currentUser", FIREBASE_AUTH?.currentUser);

  return (
    <header>
      <Box sx={sx}>
        <Box sx={sxLogo}>
          <Box component="img" src="/images/iPlanX-Logo-White.png"></Box>
        </Box>
        <Box sx={sxLeft}>
          <NavLink to={routes.Home.path}>Home</NavLink>
          <NavLink to={routes.Login.path}>Artists</NavLink>
          <NavLink to={routes.Events.list.path}>Events</NavLink>
          <NavLink to={routes.Admin.Events.Create.path}>
            Admin Event Create
          </NavLink>
          {/* <NavLink to={routes.Admin.Artists.path}>Admin Artists</NavLink>
          <NavLink to={routes.Admin.Venues.path}>Admin Venues</NavLink> */}
        </Box>
        <Box sx={sxRight}>
          {currentUser ? (
            <>
              Hi {currentUser?.displayName} (
              <a href="#" onClick={() => signOut(FIREBASE_AUTH!)}>
                Logout
              </a>
              )
            </>
          ) : (
            <NavLink to={routes.Login.path}>Login</NavLink>
          )}
        </Box>
      </Box>
    </header>
  );
};

export default Header;
