import { Box, SxProps } from "@mui/material";

export const XLine = () => {
  const sx: SxProps = {
    width: "1px !important",
    background: "#ffffff",
    opacity: 0.2,
    pt: 1,
    pb: 1,
    mr: 2,
    ml: 2,
  };
  return <Box sx={sx}></Box>;
};
