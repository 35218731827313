import { zodResolver } from "@hookform/resolvers/zod";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Page from "components/Page";
import XButton from "components/common/XButton";
import XTextField from "components/common/XTextField";
// import XTimeField from "components/common/XTimeField";
import { SubmitHandler, useForm } from "react-hook-form";
import { createEventAPI } from "shared/service/Event";
import { z } from "zod";

const schema = z.object({
  title: z.string().min(10),
  description: z.string(),
  addressStreet1: z.string(),
  addressStreet2: z.string(),
  addressCity: z.string(),
  addressState: z.string(),
  addressCountry: z.string(),
  addressPostalCode: z.string().min(5).max(5),
  ticketLink: z.string(),
  contactPhone: z.string(),
  contactEmail: z.string(),
  eventPicture: z.any(),
});

type FormFields = z.infer<typeof schema>;

export const AdminEventCreatePage = () => {
  const tmpTime = new Date("2223-11-20").getTime();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormFields>({
    resolver: zodResolver(schema),
    defaultValues: {
      title: "Patoo Birthday Bash",
      description:
        "This is Patoo's 30th birthday. Come down to luxurious venue in LA to celebrate him.",
      addressStreet1: "10450 EB Ave",
      addressStreet2: "#206",
      addressCity: "Los Angeles",
      addressState: "CA",
      addressCountry: "USA",
      addressPostalCode: "90024",
      ticketLink: "http://eventbrite.com/patoobithday-ticket-link",
      contactPhone: "+13104224242",
      contactEmail: "phone@patoo.com",
      eventPicture: null,
    },
  });

  const onSubmit: SubmitHandler<FormFields> = (data) => {
    console.log("====> data:", data);
    const event = {
      title: data.title,
      description: data.description,
      startTimestamp: tmpTime,
      endTimestamp: tmpTime,
      address: {
        street1: data.addressStreet1,
        street2: data.addressStreet2,
        city: data.addressCity,
        state: data.addressState,
        country: data.addressCountry,
        postalCode: data.addressPostalCode,
      },
      ticketLink: data.ticketLink,
      contact: { emails: [data.contactEmail], phones: [data.contactPhone] },
    };

    try {
      new Promise((resolve) => {
        console.log("====> data?.eventPicture", data?.eventPicture[0]);
        createEventAPI({
          event,
          isPrivate: true,
          image: data?.eventPicture[0],
        });
        setTimeout(resolve, 1000);
      });
      console.log("====> CREATE EVENT SUBMITTED SUCCESSFULLY", data);
    } catch (error) {
      console.log("====> zod error", error);
      setError("title", {
        message: "This title is already taken",
      });
    }
  };

  return (
    <Page title="Create Event">
      <form onSubmit={handleSubmit(onSubmit)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
            alignItems={"flex-start"}
          >
            <XTextField
              register={register}
              errors={errors.title}
              name="title"
              label="Event Title"
            />
            <XTextField
              register={register}
              errors={errors.description}
              name="description"
              label="Description"
            />
            <input
              type="file"
              accept=".jpg"
              {...register("eventPicture", {
                required: true,
              })}
            />
            {/* <Grid container>
              <Grid item>
            <XTimeField
              register={register}
              errors={errors.startTimestamp}
              name="startTimestamp"
              label="Start Time"
            />
            </Grid>
            
            <Grid item>
            <XTimeField
              register={register}
              errors={errors.startTimestamp}
              name="startTimestamp"
              label="Start Time"
            />
            </Grid>
            </Grid> */}
            <XTextField
              register={register}
              errors={errors.addressStreet1}
              name="addressStreet1"
              label="Street Address"
            />
            <XTextField
              register={register}
              errors={errors.addressStreet2}
              name="addressStreet2"
              label="Street Address 2"
            />
            <XTextField
              register={register}
              errors={errors.addressCity}
              name="addressCity"
              label="City"
            />
            <XTextField
              register={register}
              errors={errors.addressState}
              name="addressState"
              label="State"
            />
            <XTextField
              register={register}
              errors={errors.addressCountry}
              name="addressCountry"
              label="Country"
            />
            <XTextField
              register={register}
              errors={errors.addressPostalCode}
              name="addressPostalCode"
              label="Postal Code"
            />
            <XTextField
              register={register}
              errors={errors.ticketLink}
              name="ticketLink"
              label="Ticket Link"
            />
            <XTextField
              register={register}
              errors={errors.contactEmail}
              name="contactEmail"
              label="Email"
            />
            <XTextField
              register={register}
              errors={errors.contactPhone}
              name="contactPhone"
              label="Phone"
            />
            <XButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Loading..." : "Submit"}
            </XButton>
          </Box>
        </LocalizationProvider>
      </form>
    </Page>
  );
};
