import { Link } from "react-router-dom";
import theme from "styles/theme";
import { Box } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import routes from "router/routes";

const Footer = () => {
  const sx = {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    height: 60,
    padding: theme.spacing(0, 3),
    color: theme.palette.text.secondary,
    background: theme.palette.secondary.dark,
    fontSize: theme.typography.body2,
    "& a": {
      color: theme.palette.text.secondary,
      textDecoration: "none",
    },
  };
  const sxLeft = {
    textAlign: "left",
    width: 150,
  };
  const sxCenter = {
    textAlign: "center",
    flexGrow: 1,
  };
  const sxRight = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    textAlign: "right",
    width: 150,
    "& a": {
      fontSize: 0,
      ml: 2,
    },
  };

  return (
    <footer>
      <Box sx={sx}>
        <Box sx={sxLeft}>
          <Link to={routes.Login.path}>Privacy Policy</Link>
        </Box>
        <Box sx={sxCenter}>
          <p>&copy; 2023 iPlanX. All rights reserved.</p>
        </Box>
        <Box sx={sxRight}>
          <Link to="https://www.facebook.com/gigmaxter">
            <FacebookIcon fontSize="small" />
          </Link>
          <Link to="https://www.instagram.com/gigmaxter/">
            <InstagramIcon fontSize="small" />
          </Link>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
