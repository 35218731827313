import { Address, Contact, UpdateAddress } from "shared/API";
import {
  isDateValidRegex,
  isEmailValidRegex,
  isPhoneNumberRegex,
  isUrlValidRegex,
} from "shared/service/utils/regex";

export function isDefined(attribute: any) {
  return attribute !== null && attribute !== undefined;
}

export function isIdValid(id: string): boolean {
  return id.length > 0;
}

export function isStringValid(title: string): boolean {
  if (title.length < 3) {
    return false;
  }
  return true;
}

export const stateList = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
export function isAddressValid(address: Address | UpdateAddress) {
  if (isDefined(address.city) && address.city!.length < 2) {
    return false;
  }
  if (isDefined(address.country) && address.country!.length < 2) {
    return false;
  }
  if (
    address.country == "US" &&
    isDefined(address.state) &&
    !stateList.includes(address.state!)
  ) {
    return false;
  }
  return true;
}

export function isEmailValid(email: string): boolean {
  return isEmailValidRegex.test(email);
}

function validateEmailList(emailList: string[]): boolean {
  for (let i = 0; i < emailList.length; i++) {
    if (!isEmailValid(emailList[i])) {
      console.log("===> email arg", emailList);
      console.log("===> EMAIL FAILED");
      return false; // if any element fails validation, return false
    }
  }

  return true; // all elements are valid email addresses
}

function validatePhoneNumberList(phoneNumberList: string[]): boolean {
  for (let i = 0; i < phoneNumberList.length; i++) {
    if (!isPhoneNumberRegex.test(phoneNumberList[i])) {
      console.log("===> PHONE FAILED");
      return false; // if any element fails validation, return false
    }
  }

  return true; // all elements are valid phone numbers
}

export function isContactValid(contact: Contact): boolean {
  return (
    validateEmailList(contact.emails) && validatePhoneNumberList(contact.phones)
  );
}

export function isUrlValid(url: string): boolean {
  return isUrlValidRegex.test(url);
}

export function isDateValid(date: string): boolean {
  return isDateValidRegex.test(date);
}

export function isGenderValid(gender: string): boolean {
  return gender == "male" || gender == "female";
}

export function isTimestampValid(timestamp: number): boolean {
  return timestamp > Date.now();
}
