// import { Home } from "pages/Home";
// import AdminVendorsPage from 'pages/Admin/AdminVendorsPage';
// import ArtistsPage from 'pages/ArtistsPage';
// import ArtistDetailsPage from 'pages/ArtistDetailsPage';
// import AdminEventsPage from 'pages/Admin/AdminEventsPage';
// import EventsPage from 'pages/EventsPage';
// import EventDetailsPage from 'pages/Events/EventDetailsPage';
// import AdminVenuesPage from 'pages/Admin/AdminVenuesPage';

import { AuthRoute } from "components/AuthRoute";
import { AdminEventCreatePage } from "pages/Admin/AdminEventCreatePage";
import EventDetailsPage from "pages/EventDetailsPage";
import EventsPage from "pages/EventsPage";
import { Home } from "pages/Home";
import { Login } from "pages/Login";
import { SignUp } from "pages/Signup";
// import VendorDetailsPage from "pages/VendorDetailsPage";
// import VendorsPage from "pages/VendorsPage";

const routes = {
  Home: {
    path: "/",
    component: (
      <AuthRoute>
        <Home />
      </AuthRoute>
    ),
  },
  Login: {
    path: "/login",
    component: <Login />,
  },
  SignUp: {
    path: "/signup",
    component: <SignUp />,
  },
  // Vendors: {
  //   list: {
  //     path: "/vendors",
  //     component: <VendorsPage />,
  //   },
  //   details: (id = ":id") => ({
  //     path: `/vendors/${id}`,
  //     component: <VendorDetailsPage />,
  //   }),
  // },

  Events: {
    list: {
      path: "/events/",
      component: <EventsPage />,
    },
    details: () => ({
      path: `/events/details/`,
      component: <EventDetailsPage />,
    }),
    // details: (id = ":id") => ({
    //   path: `/events/${id}`,
    //   component: (
    //     <AuthRoute>
    //       <EventDetailsPage />
    //     </AuthRoute>
    //   ),
    // }),
  },

  Admin: {
    Events: {
      Create: {
        path: "/admin/events/create",
        component: <AdminEventCreatePage />,
      },
    },
    //   Artists: {
    //     path: '/admin/artists/',
    //     component: <AdminVendorsPage />,
    //   },
    //   Venues: {
    //     path: '/admin/venues/',
    //     component: <AdminVenuesPage />,
    //   },
  },
  // PrivacyPolicy: {
  //   path: '/privacy-policy',
  //   component: <>Privacy Policy</>,
  // },
  // adminArtists: '/admin/artists',
  // artists: '/artists',
  // artistDetails: '/artists/:id',
  // adminEvents: '/admin/events',
  // events: '/events',
  // eventDetails: '/events/:id',
};

export default routes;
