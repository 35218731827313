import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "styles/theme";
import { Router } from "./router";
import Header from "layout/Header";
import Footer from "layout/Footer";
import { initializeFirebase } from "shared/Firebase";

const App: React.FunctionComponent = () => {
  initializeFirebase(true);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div
          style={{
            // background:
            //   "linear-gradient(0deg, rgba(0,0,0,1) 15%, rgba(0,0,0, 0.8) 100%)",
            // filter: "grayscale(0)",
            background: "#000000",
          }}
        >
          <Header />
          <Router />
          <Footer />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
