import {
  ListNearByQueryInput,
  ListQueryInput,
  OrStatement,
  QueryInput,
} from "shared/API";
import {
  Firestore,
  Query,
  QueryCompositeFilterConstraint,
  QueryFieldFilterConstraint,
  QueryNonFilterConstraint,
  and,
  collection,
  endAt,
  limit,
  or,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import * as geoFire from "geofire-common";

function buildWhereStatements(
  orStatement: OrStatement,
): QueryCompositeFilterConstraint | undefined {
  const whereStatements: QueryFieldFilterConstraint[] = [];
  for (let index = 0; index < orStatement.whereFilters.length; index++) {
    const whereFilter = orStatement.whereFilters[index];
    whereStatements.push(
      where(whereFilter.field, whereFilter.operation, whereFilter.value),
    );
  }
  if (whereStatements.length > 0) {
    return or(...whereStatements);
  }
  return;
}

function buildFilterConstraint(
  queryInput: QueryInput,
): QueryCompositeFilterConstraint | undefined {
  const orStatements: QueryCompositeFilterConstraint[] = [];
  for (let index = 0; index < queryInput.andStatements.length; index++) {
    const statement = buildWhereStatements(queryInput.andStatements[index]);
    if (statement) {
      orStatements.push(statement);
    }
  }
  if (orStatements.length > 0) {
    return and(...orStatements);
  }
  return;
}

export function buildQuery(
  database: Firestore,
  collection_name: string,
  input: ListQueryInput,
): Query {
  const parameters: QueryNonFilterConstraint[] = [];
  let filterConstraint: QueryCompositeFilterConstraint | undefined = undefined;
  if (input.queryInput && input.queryInput.andStatements.length > 0) {
    filterConstraint = buildFilterConstraint(input.queryInput);
  }
  if (input.sortBy) {
    parameters.push(orderBy(input.sortBy.field, input.sortBy.direction));
  }
  if (input.nextToken) {
    parameters.push(startAfter(input.nextToken));
  }
  if (input.limit) {
    parameters.push(limit(input.limit));
  }
  if (filterConstraint) {
    return query(
      collection(database, collection_name),
      filterConstraint,
      ...parameters,
    );
  } else {
    return query(collection(database, collection_name), ...parameters);
  }
}

export function BuildGeoNearbyQueries(
  database: Firestore,
  collectionName: string,
  input: ListNearByQueryInput,
): Query[] {
  let filterConstraint: QueryCompositeFilterConstraint | undefined = undefined;
  if (input.queryInput && input.queryInput.andStatements.length > 0) {
    filterConstraint = buildFilterConstraint(input.queryInput);
  }
  const bounds = geoFire.geohashQueryBounds(
    [input.center.latitude, input.center.longitude],
    input.radiusInKiloMeter * 1000, // Convert to Meter
  );
  const queries: Query[] = [];
  for (const b of bounds) {
    queries.push(
      filterConstraint
        ? query(
            collection(database, collectionName),
            filterConstraint,
            orderBy("location.geoHash"),
            startAt(b[0]),
            endAt(b[1]),
          )
        : query(
            collection(database, collectionName),
            orderBy("location.geoHash"),
            startAt(b[0]),
            endAt(b[1]),
          ),
    );
  }
  return queries;
}
