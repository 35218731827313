import { zodResolver } from "@hookform/resolvers/zod";
import { Box } from "@mui/material";
import Page from "components/Page";
import XButton from "components/common/XButton";
import XTextField from "components/common/XTextField";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
// import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const schema = z.object({
  email: z.string().email(),
  password: z.string(),
  confirmPassword: z.string(),
});
// .refine((data) => data.password === data.confirmPassword, {
//   message: "Passwords don't match",
//   path: ["confirm"],
// });

type FormFields = z.infer<typeof schema>;

export const SignUp = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  //   const [authing, setAuthing] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormFields>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    console.log("====> data:", data);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password,
      );
      onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log(user);
          navigate("/");
        } else {
          console.log("No user info found!");
        }
      });
      console.log("=====> LOGGED IN AS:", userCredential);
    } catch (error) {
      console.log("=====> error", error, errors);
      setError("email", { message: "Invalid credentials. Please try again" });
    }
  };

  return (
    <Page title="Sign Up">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          alignItems={"flex-start"}
        >
          <XTextField
            register={register}
            errors={errors.email}
            name="email"
            label="Email"
          />
          <XTextField
            register={register}
            errors={errors.password}
            name="password"
            type="password"
            label="Password"
          />
          {/* <XTextField
            register={register}
            errors={errors.confirmPassword}
            name="confirmPassword"
            type="password"
            label="Confirm Password"
          /> */}
          <XButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Loading..." : "Sign Up"}
          </XButton>
        </Box>
      </form>
    </Page>
  );
};
