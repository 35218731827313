import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface IAuthRouteProps {
  children: any;
}

export const AuthRoute = (props: IAuthRouteProps) => {
  const { children } = props;
  const auth = getAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => AuthCheck();
  }, [auth]);

  const AuthCheck = onAuthStateChanged(auth, (user) => {
    if (user) {
      setLoading(false);
    } else {
      console.log("Unauthorized");
      navigate("/login");
    }
  });

  if (loading) return <p>Loading......</p>;

  return <>{children}</>;
};
