const firebaseConfig = {
  apiKey: "AIzaSyA0ehVgb5U98caIwl4C-h55i7R78L_IsPs",
  authDomain: "avid-subject-268906.firebaseapp.com",
  projectId: "avid-subject-268906",
  storageBucket: "avid-subject-268906.appspot.com",
  messagingSenderId: "153319490089",
  appId: "1:153319490089:web:0d770cada65a39fa7b9238",
  measurementId: "G-6MV19GDRP1",
};

export default firebaseConfig;
