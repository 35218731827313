import { validateEventInput } from "shared/service/utils/InputValidator";
import {
  CreateEventInput,
  DeleteObjectInput,
  Event,
  GetObjectInput,
  ListQueryInput,
  UpdateEventInput,
  ModelResponse,
  ListNearByQueryInput,
} from "shared/API";
import { Timestamp } from "firebase/firestore";
import { FIREBASE_ANALYTICS, FIREBASE_AUTH, LOGGER } from "shared/Firebase";
import { IsImageValid, getFileDirectory } from "shared/service/utils/ImageUtil";
import { saveImage } from "shared/service/Storage";
import {
  createFirestoreObjectPrivileged,
  deleteFirestoreObject,
  getFirestoreObjectById,
  listFirestoreObjects,
  listNearbyObjects,
  updateFirestoreObject,
} from "shared/service/utils/FirestoreUtils";
import { XStatus } from "shared/service/utils/XStatus";
import { logEvent } from "firebase/analytics";

const EVENT_COLLECTION = "events";
const PRIVATE_EVENT_COLLECTION = "privateevents";

export async function eventsAPI(input: {
  query: ListQueryInput;
  isPrivate?: boolean;
}): Promise<ModelResponse<Event>> {
  return listFirestoreObjects<Event>(
    input.isPrivate ? PRIVATE_EVENT_COLLECTION : EVENT_COLLECTION,
    input,
  );
}

export async function eventByIdAPI(input: {
  event: GetObjectInput;
  isPrivate?: boolean;
}): Promise<Event> {
  return getFirestoreObjectById<Event>(
    input.isPrivate ? PRIVATE_EVENT_COLLECTION : EVENT_COLLECTION,
    input.event,
  );
}

export async function eventsNearbyAPI(input: {
  isPrivate?: boolean;
  query: ListNearByQueryInput;
}): Promise<Event[]> {
  return listNearbyObjects<Event>(
    input.isPrivate ? PRIVATE_EVENT_COLLECTION : EVENT_COLLECTION,
    input.query,
  );
}

function eventIdGenerator(input: { id?: string; title: string }): string {
  const nowTimestamp = Timestamp.now().toMillis();
  return input.id
    ? input.id
    : `${input.title.replace(/ /g, "-").toLowerCase()}-${nowTimestamp}`;
}

export async function createEventAPI(input: {
  event: CreateEventInput;
  image?: File;
  isPrivate?: boolean;
}): Promise<string> {
  console.log("====> input.image", input.image);
  if (input.image && IsImageValid(input.image) != XStatus.Valid) {
    throw XStatus.InvalidType;
  }
  const eventId = await createFirestoreObjectPrivileged<CreateEventInput>(
    input.isPrivate ? PRIVATE_EVENT_COLLECTION : EVENT_COLLECTION,
    input.event,
    validateEventInput,
    eventIdGenerator,
  );
  const uid = FIREBASE_AUTH!.currentUser!.uid;
  if (input.image) {
    LOGGER!.info(input);
    saveImage({
      directoryName: getFileDirectory(uid, "images"),
      fileName: input.image.name,
      file: input.image,
      metadata: {
        customMetadata: {
          association: `event-${eventId}}`,
        },
      },
    });
  }
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "event_creation", { uid });
  }
  return eventId;
}

export async function deleteEventAPI(input: {
  event: DeleteObjectInput;
  isPrivate?: boolean;
}): Promise<void> {
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "event_deletion", {
      uid: FIREBASE_AUTH!.currentUser!.uid,
    });
  }
  await deleteFirestoreObject(
    input.isPrivate ? PRIVATE_EVENT_COLLECTION : EVENT_COLLECTION,
    input.event,
  );
}

export async function updateEventAPI(input: {
  event: UpdateEventInput;
  image?: File;
  isPrivate?: boolean;
}): Promise<void> {
  if (input.image && IsImageValid(input.image) != XStatus.Valid) {
    throw XStatus.InvalidType;
  }
  await updateFirestoreObject<UpdateEventInput>(
    input.isPrivate ? PRIVATE_EVENT_COLLECTION : EVENT_COLLECTION,
    input.event,
    validateEventInput,
  );
  const uid = FIREBASE_AUTH!.currentUser!.uid;
  if (input.image) {
    saveImage({
      directoryName: getFileDirectory(uid, "images"),
      fileName: input.image.name,
      file: input.image,
      metadata: {
        customMetadata: {
          association: `event-${input.event.id}`,
        },
      },
    });
  }
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS!, "event_update", { uid });
  }
}
