import { Routes, Route } from "react-router-dom";
import routes from "./routes";

export const Router = () => {
  return (
    <Routes>
      <Route path={routes.Home.path} element={routes.Home.component} />
      <Route path={routes.Login.path} element={routes.Login.component} />
      <Route path={routes.SignUp.path} element={routes.SignUp.component} />
      <Route
        path={routes.Events.list.path}
        element={routes.Events.list.component}
      />
      <Route
        path={routes.Events.details().path}
        element={routes.Events.details().component}
      />
      <Route
        path={routes.Admin.Events.Create.path}
        element={routes.Admin.Events.Create.component}
      />
      {/* <Route
        path={routes.Admin.Artists.path}
        element={routes.Admin.Artists.component}
      />
      <Route
        path={routes.Admin.Venues.path}
        element={routes.Admin.Venues.component}
      />
      <Route
        path={routes.Artists.list.path}
        element={routes.Artists.list.component}
      />
      <Route
        path={routes.Artists.details().path}
        element={routes.Artists.details().component}
      />*/}
    </Routes>
  );
};
