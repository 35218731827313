import { Button, SxProps } from "@mui/material";

interface IXButtonProps {
  disabled?: boolean;
  children?: any;
  variant?: "primary" | "secondary" | "noborder";
  to?: string;
  type?: "submit";
  onClick?: () => void;
}

const XButton = (props: IXButtonProps) => {
  const isPrimary = props.variant != "secondary" && props.variant != "noborder";
  let border = "1px solid #FF3A3F";

  switch (props.variant) {
    case "secondary":
      border = "1px solid #FFFFFF";
      break;
    case "noborder":
      border = "1px solid transparent";
      break;
    default:
      border = "1px solid #FF3A3F";
      break;
  }

  const sxRoot: SxProps = {
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: 500,
    color: "#ffffff",
    py: 1.5,
    px: 5,
    textTransform: "capitalize",
    background: isPrimary ? "#FF3A3F" : "transparent",
    border,
    boxSizing: "border-box",
    borderRadius: 0,
    width: "100%",
    maxWidth: "400px",
    ":hover": {
      opacity: 0.9,
      background: isPrimary ? "#FF3A3F" : "transparent",
    },
  };

  return (
    <>
      {props.to && (
        <Button disabled={props.disabled} sx={sxRoot} href={props.to}>
          {props.children}
        </Button>
      )}
      {props.onClick && (
        <Button disabled={props.disabled} sx={sxRoot} onClick={props.onClick}>
          {props.children}
        </Button>
      )}
      {props.type === "submit" && (
        <Button disabled={props.disabled} sx={sxRoot} type="submit">
          {props.children}
        </Button>
      )}
    </>
  );
};

export default XButton;
