import {
  CreateChatInput,
  CreateEventInput,
  CreateMessageInput,
  CreateVendorInput,
  SocialMedia,
  UpdateChatInput,
  UpdateEventInput,
  UpdateMessageInput,
  UpdateVendorInput,
} from "shared/API";
import {
  isAddressValid,
  isContactValid,
  isDefined,
  isStringValid,
  isTimestampValid,
  isUrlValid,
} from "shared/utils/EntityValidator";
import { XStatus } from "shared/service/utils/XStatus";

export function validateEventInput(
  eventInput: CreateEventInput | UpdateEventInput,
): XStatus {
  if (isDefined(eventInput.title) && !isStringValid(eventInput.title!)) {
    return XStatus.InvalidTitle;
  }
  if (isDefined(eventInput.ticketLink) && !isUrlValid(eventInput.ticketLink!)) {
    return XStatus.InvalidTicketLink;
  }
  if (
    isDefined(eventInput.description) &&
    !isStringValid(eventInput.description!)
  ) {
    return XStatus.InvalidDescription;
  }
  if (
    isDefined(eventInput.startTimestamp) &&
    !isTimestampValid(eventInput.startTimestamp!)
  ) {
    return XStatus.InvalidStartTime;
  }
  if (
    isDefined(eventInput.endTimestamp) &&
    ((isDefined(eventInput.startTimestamp) &&
      eventInput.startTimestamp! > eventInput.endTimestamp!) ||
      !isTimestampValid(eventInput.endTimestamp!))
  ) {
    return XStatus.InvalidEndTime;
  }
  if (eventInput.contact && !isContactValid(eventInput.contact)) {
    return XStatus.InvalidContact;
  }
  if (isDefined(eventInput.address) && !isAddressValid(eventInput.address!)) {
    return XStatus.InvalidAddress;
  }
  return XStatus.Valid;
}

export function validateVendorInput(
  vendorInput: CreateVendorInput | UpdateVendorInput,
): XStatus {
  if (
    isDefined(vendorInput.username) &&
    !isStringValid(vendorInput.username!)
  ) {
    return XStatus.InvalidUsername;
  }
  if (
    isDefined(vendorInput.displayName) &&
    !isStringValid(vendorInput.displayName!)
  ) {
    return XStatus.InvalidName;
  }
  if (
    isDefined(vendorInput.headline) &&
    !isStringValid(vendorInput.headline!)
  ) {
    return XStatus.InvalidHeadline;
  }
  if (
    isDefined(vendorInput.description) &&
    !isStringValid(vendorInput.description!)
  ) {
    return XStatus.InvalidDescription;
  }
  if (vendorInput.contact && !isContactValid(vendorInput.contact)) {
    return XStatus.InvalidContact;
  }
  if (isDefined(vendorInput.address) && !isAddressValid(vendorInput.address!)) {
    return XStatus.InvalidAddress;
  }
  if (isDefined(vendorInput.socialMedia)) {
    const status = isSocialMediaValid(vendorInput.socialMedia!);
    if (status != XStatus.Valid) {
      return status;
    }
  }
  return XStatus.Valid;
}

export function validateChatInput(
  chatInput: CreateChatInput | UpdateChatInput,
): XStatus {
  if (isDefined(chatInput.title) && !isStringValid(chatInput.title!)) {
    return XStatus.InvalidTitle;
  }
  return XStatus.Valid;
}

export function validateCreateMessageInput(
  messageInput: CreateMessageInput,
): XStatus {
  if (isDefined(messageInput.text) && !isStringValid(messageInput.text!)) {
    return XStatus.InvalidText;
  }

  if (
    isDefined(messageInput.senderId) &&
    !isStringValid(messageInput.senderId!)
  ) {
    return XStatus.InvalidSenderId;
  }
  return XStatus.Valid;
}

export function validateUpdateMessageInput(
  messageInput: UpdateMessageInput,
): XStatus {
  if (isDefined(messageInput.text) && !isStringValid(messageInput.text!)) {
    return XStatus.InvalidText;
  }
  return XStatus.Valid;
}

export function isSocialMediaValid(socialMedia: SocialMedia): XStatus {
  if (isDefined(socialMedia.website) && !isUrlValid(socialMedia.website!)) {
    return XStatus.InvalidWebsite;
  }
  if (isDefined(socialMedia.facebook) && !isUrlValid(socialMedia.facebook!)) {
    return XStatus.InvalidFacebook;
  }
  if (isDefined(socialMedia.instagram) && !isUrlValid(socialMedia.instagram!)) {
    return XStatus.InvalidInstagram;
  }
  if (isDefined(socialMedia.youtube) && !isUrlValid(socialMedia.youtube!)) {
    return XStatus.InvalidYoutube;
  }
  if (isDefined(socialMedia.tikTok) && !isUrlValid(socialMedia.tikTok!)) {
    return XStatus.InvalidTikTok;
  }
  if (isDefined(socialMedia.twitter) && !isUrlValid(socialMedia.twitter!)) {
    return XStatus.InvalidTwitter;
  }
  if (isDefined(socialMedia.telegram) && !isUrlValid(socialMedia.telegram!)) {
    return XStatus.InvalidTelegram;
  }
  if (
    isDefined(socialMedia.soundCloud) &&
    !isUrlValid(socialMedia.soundCloud!)
  ) {
    return XStatus.InvalidSoundCloud;
  }
  if (isDefined(socialMedia.spotify) && !isUrlValid(socialMedia.spotify!)) {
    return XStatus.InvalidSpotify;
  }
  if (
    isDefined(socialMedia.appleMusic) &&
    !isUrlValid(socialMedia.appleMusic!)
  ) {
    return XStatus.InvalidAppleMusic;
  }
  if (isDefined(socialMedia.whatsapp) && !isUrlValid(socialMedia.whatsapp!)) {
    return XStatus.InvalidWhatsapp;
  }
  if (isDefined(socialMedia.onlyFans) && !isUrlValid(socialMedia.onlyFans!)) {
    return XStatus.InvalidOnlyFans;
  }
  return XStatus.Valid;
}
