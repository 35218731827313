import { useState, useEffect } from "react";
import Page from "components/Page";
import { Box, Typography } from "@mui/joy";
// import { defaultAddressFormatter } from "utils/formatter";
import { SxProps } from "@mui/material";
// import { getEvent } from "graphql/queries";
// import { eventByIdAPI } from "services/event/Event";
// import { XEvent } from "utils/UITypes";
// import { useParams } from "react-router-dom";
// import { fetchContent } from "services/file/Storage";
import { Event, SponsorStatus } from "shared/API";
import { XIconed } from "components/common/XIconed";
import {
  iCalendar,
  iEarth,
  iLocation,
  iTimeCircle,
  iUsers,
} from "components/common/XIcons";

const EventDetailsPage = () => {
  //   const { id } = useParams();

  const [event, setEvent] = useState<Event>();
  const [imageURL] = useState<string>();

  // TODO: Mahdi to fix the background gradient
  document.body.style.backgroundImage = `url(${imageURL})`;

  //   useEffect(() => {
  //     if (event && event?.image) {
  //       new Promise<string>(() => {
  //         fetchContent(String(event.image?.keyOrUrl), event.image?.bucketId)
  //           .then((result) => {
  //             setImageURL(result);
  //           })
  //           .catch((error) => {
  //             // TODO MR: fix the error
  //             console.log("====> error", error);
  //           });
  //       });
  //     }
  //   }, [event]);

  //   useEffect(() => {
  //     if (id)
  //       eventByIdAPI({ query: getEvent, variables: { id } }).then((response) => {
  //         console.log("response", response);
  //         setEvent(response);
  //       });
  //   }, [id]);

  useEffect(() => {
    const mockEvent: Event = {
      id: "1",
      date: "8.64e15",
      title: "Sara Naeini & Reza Rohani Live in Los Angeles",
      imageUrl:
        "https://images.squarespace-cdn.com/content/v1/60bb12d8672ab86c915cdeb1/89e1acb0-ed43-4a8b-b79e-aec4715147b8/Kamyar.jpeg",
      startTimestamp: 1714269600,
      sponsorStatus: SponsorStatus.None,
      ownerId: "1",
      createdAt: 1,
      address: {
        street1: "",
        city: "Los Angeles",
        state: "CA",
        country: "USA",
        postalCode: "",
      },
      hosts: [
        { displayName: "Deejay AL", username: "1" },
        { displayName: "DJ Sepand", username: "2" },
      ],
    };
    setEvent(mockEvent);
  }, []);

  const sxEventDetailsContainer: SxProps = {
    display: "flex",
    gap: 10,
    width: "100%",
  };

  // const sxVendorImg: SxProps = {
  //   width: 400,
  // };

  const sxEventDetails: SxProps = {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    fontSize: "16px",
    fontWeight: 300,
    color: "#ffffff",
  };

  const sxH1: SxProps = {
    fontSize: 48,
    fontWeight: 600,
    color: "#ffffff",
  };

  const sxDivider: SxProps = {
    background:
      "linear-gradient(90deg, rgba(204, 204, 204, 0.2) 0%, rgba(204, 204, 204, 0) 60%)",
    height: "1px",
  };

  const sxDateTime: SxProps = {
    display: "flex",
    gap: 12,
  };

  const sxList: SxProps = {
    display: "flex",
    gap: 4,
  };

  const sxEventTicketsContainer: SxProps = {
    textAlign: "right",
  };
  // const sxButton: SxProps = {
  //   fontFamily: "Poppins",
  //   fontSize: 24,
  //   fontWeight: 500,
  //   background: "#FF3A3F",
  //   color: "#ffffff",
  //   borderRadius: 0,
  //   py: 3,
  //   px: 5,
  // };
  // console.log("====> items", event?.hosts?.items);

  return (
    <Page>
      {event ? (
        <>
          <Box sx={sxEventDetailsContainer}>
            {/* <Box component="img" src={imageURL} sx={sxVendorImg}></Box> */}
            <img src={event.imageUrl} width="400" />
            <Box sx={sxEventDetails}>
              <Typography level="h1" sx={sxH1}>
                {event.title}
              </Typography>
              <XIconed
                icon={iLocation}
                text={`${event.address?.city}, ${event.address?.state}`}
              />
              <Box sx={sxDivider}></Box>
              <Box sx={sxDateTime}>
                <XIconed icon={iCalendar} text="Jan 27th, 2024" />
                <XIconed icon={iTimeCircle} text="7:00 PM" />
              </Box>
              <Box sx={sxDivider}></Box>
              {
                <>
                  <XIconed
                    icon={iUsers}
                    text="Artists"
                    opts={{ uppercase: true }}
                  >
                    <Box sx={sxList}>
                      <Box>Sara Naeini</Box>
                      <Box>Reza Rohani</Box>
                    </Box>
                  </XIconed>
                </>
              }
              {event.hosts && (
                <>
                  <XIconed
                    icon={iEarth}
                    text="Hosts"
                    opts={{ uppercase: true }}
                  >
                    <Box sx={sxList}>
                      {event.hosts.map((x, i) => {
                        return <Box key={i}>{x.displayName}</Box>;
                      })}
                    </Box>
                  </XIconed>
                </>
              )}
            </Box>
          </Box>
          <Box sx={sxEventTicketsContainer}>
            {/* <Button size="lg" component="a" href="#as-link" sx={sxButton}>
              Buy Tickets
            </Button> */}
          </Box>
        </>
      ) : (
        "Ooops! The event that you are looking for does not exist!"
      )}
    </Page>
  );
};

export default EventDetailsPage;
