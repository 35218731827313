import { Typography } from "@mui/joy";
import { Box, SxProps } from "@mui/material";
import React, { ReactNode } from "react";
import theme from "styles/theme";

interface IPageProps {
  title?: string;
  customCSS?: any;
  isAdmin?: boolean;
  children: ReactNode;
}

const Page = (props: IPageProps) => {
  const sxRoot: SxProps = {
    color: props.isAdmin ? "#000000" : "#ffffff",
    minHeight: `calc(100vh - 120px)`,
    px: 15,
    mb: 15,
    fontSize: theme.typography.body1,
    ...props.customCSS,
  };

  return (
    <main>
      <Box sx={sxRoot}>
        {props.title && <Typography level="h1">{props.title}</Typography>}
        {props.children}
      </Box>
    </main>
  );
};

export default Page;
