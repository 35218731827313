import { Box } from "@mui/joy";
import { SxProps } from "@mui/material";

interface IXIconedProps {
  text: string;
  size?: "small" | "medium" | "large";
  icon?: string;
  opts?: {
    uppercase: boolean;
  };
  children?: any;
}

export const XIconed = (props: IXIconedProps) => {
  const textFormat = () => {
    switch (props.size) {
      case "small":
        return {
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "24px",
        };
      case "medium":
        return {
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "24px",
        };
      case "large":
        return {
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "24px",
        };
      default:
        return {
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "24px",
        };
    }
  };

  const imgFormat = () => {
    switch (props.size) {
      case "small":
        return { width: 20 };
      case "medium":
        return { width: 20 };
      case "large":
        return { width: 20 };
      default:
        return { width: 20 };
    }
  };

  const sxRoot: SxProps = {
    display: "flex",
    flexDirection: "column",
    gap: 1,
  };

  const sxH4: SxProps = {
    ...textFormat(),
    display: "flex",
    gap: 1,
    alignItems: "center",
    textTransform: props.opts?.uppercase ? "uppercase" : "none",
    "& span": {
      opacity: props.opts?.uppercase ? "0.5" : "1",
    },
  };
  const sxList = {};

  return (
    <Box sx={sxRoot}>
      <Box sx={sxH4}>
        {props.icon && <img src={props.icon} style={imgFormat()} />}{" "}
        <span>{props.text}</span>
      </Box>
      {props.children && <Box sx={sxList}>{props.children}</Box>}
    </Box>
  );
};
