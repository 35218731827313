import XEventRow from "components/cards/XEventRow";
import Page from "components/Page";
import { useEffect, useState } from "react";
import { Event } from "shared/API";
import { FIREBASE_AUTH } from "shared/Firebase";
import { eventsAPI } from "shared/service/Event";

const EventsPage = () => {
  const [events, setEvents] = useState<Array<Event>>();

  useEffect(() => {
    // const mockEvents: Array<Event> = [
    //   {
    //     id: "1",
    //     date: "8.64e15",
    //     title: "Kamyar Live in Paris",
    //     imageUrl:
    //       "https://images.squarespace-cdn.com/content/v1/60bb12d8672ab86c915cdeb1/89e1acb0-ed43-4a8b-b79e-aec4715147b8/Kamyar.jpeg",
    //     startTimestamp: 1714269600,
    //     sponsorStatus: SponsorStatus.None,
    //     ownerId: "1",
    //     createdAt: 1,
    //     address: {
    //       street1: "",
    //       city: "Las Vegas",
    //       state: "NV",
    //       country: "USA",
    //       postalCode: "",
    //     },
    //   },
    //   {
    //     id: "2",
    //     date: "8.64e15",
    //     title: "Sara Naeini & Reza Rohani Live in Los Angeles",
    //     imageUrl:
    //       "https://i.scdn.co/image/ab6761610000e5eb600562514eb3454dfcd93243",
    //     startTimestamp: 1713664800,
    //     sponsorStatus: SponsorStatus.Gold,
    //     ownerId: "2",
    //     createdAt: 2,
    //     address: {
    //       street1: "",
    //       city: "Los Angeles",
    //       state: "CA",
    //       country: "USA",
    //       postalCode: "",
    //     },
    //   },
    //   {
    //     id: "3",
    //     date: "8.64e15",
    //     title: "Zedbazi Live in San Francisco",
    //     imageUrl:
    //       "https://i.scdn.co/image/ab6761610000e5eb600562514eb3454dfcd93243",
    //     startTimestamp: 1713664800,
    //     sponsorStatus: SponsorStatus.Gold,
    //     ownerId: "2",
    //     createdAt: 2,
    //     address: {
    //       street1: "",
    //       city: "San Francisco",
    //       state: "CA",
    //       country: "USA",
    //       postalCode: "",
    //     },
    //   },
    //   {
    //     id: "4",
    //     date: "8.64e15",
    //     title: "David Guetta Live in Miami",
    //     imageUrl:
    //       "https://i.scdn.co/image/ab6761610000e5eb600562514eb3454dfcd93243",
    //     startTimestamp: 1713664800,
    //     sponsorStatus: SponsorStatus.Gold,
    //     ownerId: "2",
    //     createdAt: 2,
    //     address: {
    //       street1: "",
    //       city: "Miami",
    //       state: "FL",
    //       country: "USA",
    //       postalCode: "",
    //     },
    //   },
    //   {
    //     id: "5",
    //     date: "8.64e15",
    //     title: "Homayoun Shajarian Live in Los Angeles",
    //     imageUrl:
    //       "https://i.scdn.co/image/ab6761610000e5eb600562514eb3454dfcd93243",
    //     startTimestamp: 1713664800,
    //     sponsorStatus: SponsorStatus.Gold,
    //     ownerId: "2",
    //     createdAt: 2,
    //     address: {
    //       street1: "",
    //       city: "Los Angeles",
    //       state: "CA",
    //       country: "USA",
    //       postalCode: "",
    //     },
    //   },
    // ];
    // setEvents(mockEvents);
    eventsAPI({
      query: {
        limit: 10,
        queryInput: {
          andStatements: [
            {
              whereFilters: [
                {
                  field: "ownerId",
                  operation: "==",
                  value: FIREBASE_AUTH!.currentUser!.uid,
                },
              ],
            },
          ],
        },
      },
      isPrivate: true,
    }).then((response) => setEvents(response.items as Array<Event>));
  }, []);

  if (events)
    return (
      <Page title="Events">
        <XEventRow events={events} />
      </Page>
    );
};

export default EventsPage;
