/*
This file is autogenerated. Please don't make any changes.
The original file can be found at: https://github.com/iplanx/backend/blob/master/functions/src/service/API.ts
*/

import { QueryDocumentSnapshot, WhereFilterOp } from "firebase/firestore";
import { UploadMetadata } from "firebase/storage";

enum Deejay {
  EngagementDJ = "Deejay.EngagementDJ",
  HousePartyDJ = "Deejay.HousePartyDJ",
  WeddingDJ = "Deejay.WeddingDJ",
  CubDJ = "Deejay.CubDJ",
  RadioDJ = "Deejay.RadioDJ",
  FestivalDJ = "Deejay.FestivalDJ",
  DJController = "Deejay.DJController",
}

enum Lighting {
  MovingHead = "Lighting.MovingHead",
  LEDLight = "Lighting.LEDLight",
  UpLight = "Lighting.UpLight",
  Laser = "Lighting.Laser",
  LightController = "Lighting.LightController",
}

enum Effect {
  Firework = "Effect.Firework",
  Flame = "Effect.Flame",
  Co2Gun = "Effect.Co2Gun",
  Co2Jet = "Effect.Co2Jet",
  Co2Tank = "Effect.Co2Tank",
  Fog = "Effect.Fog",
}

enum Visual {
  LEDWall = "Visual.LEDWall",
  Projector = "Visual.Projector",
  Screen = "Visual.Screen",
}

enum Sound {
  SpeakerStand = "Sound.SpeakerStand",
  WirelessMic = "Sound.WirelessMic",
  WiredMic = "Sound.WiredMic",
  SubSpeaker = "Sound.SubSpeaker",
  PoweredSpeaker = "Sound.PoweredSpeaker",
  Monitor = "Sound.Monitor",
  Mixer = "Sound.Mixer",
}

enum Photography {
  WeddingPhotography = "Photography.WeddingPhotography",
  EventPhotography = "Photography.EventPhotography",
  FashionPhotography = "Photography.FashionPhotography",
  DronePhotography = "Photography.DronePhotography",
  ConcertPhotography = "Photography.ConcertPhotography",
  StudioPhotography = "Photography.StudioPhotography",
  PhotoEditing = "Photography.PhotoEditing",
}

enum Videography {
  WeddingVideography = "Videography.WeddingVideography",
  EventVideography = "Videography.EventVideography",
  MusicVideography = "Videography.MusicVideography",
  LifestyleVideography = "Videography.LifestyleVideography",
  SportsVideography = "Videography.SportsVideography",
  DroneVideography = "Videography.DroneVideography",
  VideoEditing = "Videography.VideoEditing",
  SocialMediaVideography = "Videography.SocialMediaVideography",
  TVShowVideography = "Videography.TVShowVideography",
  ConcertVideography = "Videography.ConcertVideography",
}

enum Singer {
  LivePerformance = "Singer.LivePerformance",
  WeddingSinger = "Singer.WeddingSinger",
  BackgroundSinger = "Singer.BackgroundSinger",
  MusicalPlays = "Singer.MusicalPlays",
  Voiceover = "Singer.Voiceover",
  LessonsAndCoaching = "Singer.LessonsAndCoaching",
  OnlinePerformance = "Singer.OnlinePerformance",
  EducationalWorkshops = "Singer.EducationalWorkshops",
  WorshipService = "Singer.WorshipService",
  CruisePerformance = "Singer.CruisePerformance",
  ClubPerformance = "Singer.ClubPerformance",
  BarPerformance = "Singer.BarPerformance",
  TVPerformance = "Singer.TVPerformance",
  ConcertPerformance = "Singer.ConcertPerformance",
  Competitions = "Singer.Competitions",
}

enum Instrumentalist {
  Piano = "Instrumentalist.Piano",
  GuitarAcoustic = "Instrumentalist.GuitarAcoustic",
  GuitarElectric = "Instrumentalist.GuitarElectric",
  GuitarBass = "Instrumentalist.GuitarBass",
  Violin = "Instrumentalist.Violin",
  Drum = "Instrumentalist.Drum",
  Percussion = "Instrumentalist.Percussion",
  Cello = "Instrumentalist.Cello",
  Saxophone = "Instrumentalist.Saxophone",
  Trumpet = "Instrumentalist.Trumpet",
  Flute = "Instrumentalist.Flute",
  Keyboard = "Instrumentalist.Keyboard",
  Accordion = "Instrumentalist.Accordion",
  Sitar = "Instrumentalist.Sitar",
  MusicTeacher = "Instrumentalist.MusicTeacher",
}

enum Conductor {
  OrchestralConductor = "Conductor.OrchestralConductor",
  ChoirConductor = "Conductor.ChoirConductor",
  BandConductor = "Conductor.BandConductor",
}

enum Producer {
  MusicProducer = "Producer.MusicProducer",
  BeatProducer = "Producer.BeatProducer",
  Songwriter = "Producer.Songwriter",
  Arranger = "Producer.Arranger",
  Composer = "Producer.Composer",
}

enum Staff {
  WeddingPlanner = "Staff.WeddingPlanner",
  EventPlanner = "Staff.EventPlanner",
  Valet = "Staff.Valet",
  Security = "Staff.Security",
  Usher = "Staff.Usher",
  Server = "Staff.Server",
  Bartender = "Staff.Bartender",
}

enum Staging {
  DanceFloor = "Staging.DanceFloor",
  Stage = "Staging.Stage",
  Truss = "Staging.Truss",
  LiftForTruss = "Staging.LiftForTruss",
  Podium = "Staging.Podium",
  Platform = "Staging.Platform",
}

enum Designer {
  HairArtist = "Designer.HairArtist",
  MakeupArtist = "Designer.MakeupArtist",
  FashionDesigner = "Designer.FashionDesigner",
  FlyerDesigner = "Designer.FlyerDesigner",
}

enum Decorator {
  EngagementDecoration = "Decorator.EngagementDecoration",
  BirthdayDecoration = "Decorator.BirthdayDecoration",
  WeddingDecoration = "Decorator.WeddingDecoration",
  FoodTableDecoration = "Decorator.FoodTableDecoration",
  AppetizerDecoration = "Decorator.AppetizerDecoration",
  BalloonDecoration = "Decorator.BalloonDecoration",
  FlowerDecoration = "Decorator.FlowerDecoration",
  Backdrop = "Decorator.Backdrop",
  PhotoBooth = "Decorator.PhotoBooth",
  InflatableStructure = "Decorator.InflatableStructure",
}

enum Rental {
  Car = "Rental.Car",
  Tent = "Rental.Tent",
  PhotoBooth360 = "Rental.PhotoBooth360",
  Heater = "Rental.Heater",
  FanAndAC = "Rental.FanAndAC",
  Generator = "Rental.Generator",
  BarEquipment = "Rental.BarEquipment",
  PortableRestroom = "Rental.PortableRestroom",
  Barrier = "Rental.Barrier",
  Stanchion = "Rental.Stanchion",
  Signage = "Rental.Signage",
  ParkingEquipment = "Rental.ParkingEquipment",
}

enum Furniture {
  Table = "Furniture.Table",
  Chair = "Furniture.Chair",
  Tablecloth = "Furniture.Tablecloth",
  CocktailTable = "Furniture.CocktailTable",
  LoungeSeating = "Furniture.LoungeSeating",
}

enum Catering {
  FoodWarmer = "Catering.FoodWarmer",
  Dishes = "Catering.Dishes",
  Utensil = "Catering.Utensil",
  Napkin = "Catering.Napkin",
  Plate = "Catering.Plate",
  Glasses = "Catering.Glasses",
}

enum Performer {
  BalletDancer = "Performer.BalletDancer",
  LatinDancer = "Performer.LatinDancer",
  BallroomDancer = "Performer.BallroomDancer",
  BreakDancer = "Performer.BreakDancer",
  TapDancer = "Performer.TapDancer",
  BellyDancer = "Performer.BellyDancer",
  Aerialist = "Performer.Aerialist",
  TraditionalDancer = "Performer.TraditionalDancer",
  ClubDancer = "Performer.ClubDancer",
  Choreographer = "Performer.Choreographer",
  FestivalDancer = "Performer.FestivalDancer",
  PoleDancer = "Performer.PoleDancer",
  ExoticShow = "Performer.ExoticShow",
  FireDancer = "Performer.FireDancer",
  LEDDancer = "Performer.LEDDancer",
  GlowDancer = "Performer.GlowDancer",
  AcrobaticShow = "Performer.AcrobaticShow",
  StiltPerformer = "Performer.StiltPerformer",
  SynchronizedDancer = "Performer.SynchronizedDancer",
  Magician = "Performer.Magician",
  CaricatureArtist = "Performer.CaricatureArtist",
  Comedian = "Performer.Comedian",
  MC = "Performer.MC",
  Jugglers = "Performer.Jugglers",
}

enum TopLevelVendorType {
  Deejay = "Deejay",
  Lighting = "Lighting",
  Effect = "Effect",
  Visual = "Visual",
  Sound = "Sound",
  Photography = "Photography",
  Videography = "Videography",
  Singer = "Singer",
  Instrumentalist = "Instrumentalist",
  Conductor = "Conductor",
  Producer = "Producer",
  Staging = "Staging",
  Designer = "Designer",
  Decorator = "Decorator",
  Rental = "Rental",
  Furniture = "Furniture",
  Catering = "Catering",
  Performer = "Performer",
}

export type VendorType =
  | TopLevelVendorType
  | Rental
  | Deejay
  | Lighting
  | Effect
  | Visual
  | Sound
  | Photography
  | Videography
  | Singer
  | Conductor
  | Producer
  | Staff
  | Staging
  | Designer
  | Decorator
  | Rental
  | Furniture
  | Catering
  | Performer
  | Instrumentalist;
export const VendorType = {
  ...TopLevelVendorType,
  ...Rental,
  ...Deejay,
  ...Lighting,
  ...Effect,
  ...Visual,
  ...Sound,
  ...Photography,
  ...Videography,
  ...Singer,
  ...Conductor,
  ...Producer,
  ...Staff,
  ...Staging,
  ...Designer,
  ...Decorator,
  ...Rental,
  ...Furniture,
  ...Catering,
  ...Performer,
  ...Instrumentalist,
};

export function convertToVendorType(typeStr: string): VendorType | undefined {
  const vendorTypeValue = VendorType[typeStr as keyof typeof VendorType];
  return vendorTypeValue;
}

export type VenueStub = {
  username: string;
  displayName: string;
};

export type VendorStub = {
  username: string;
  displayName: string;
};

export type Contact = {
  emails: string[];
  phones: string[];
};

export type GeoLocation = {
  latitude: number;
  longitude: number;
  geoHash?: string;
};

export type GeoLocationInput = {
  latitude: number;
  longitude: number;
};

export type Address = {
  street1: string;
  street2?: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
};

export type UpdateAddress = {
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
};

export enum SponsorStatus {
  None,
  Gold,
  Platinum,
}

export type Event = {
  id: string;
  title: string;
  imageUrl?: string;
  description?: string;
  startTimestamp: number;
  endTimestamp?: number;
  sponsorStatus: SponsorStatus;
  ticketLink?: string;
  tags?: string[];
  location?: GeoLocation;
  contact?: Contact;
  venue?: VenueStub;
  hosts?: VendorStub[];
  artists?: VendorStub[];
  address?: Address;
  createdAt: number;
  date?: string;
  ownerId: string;
};

export type CreateEventInput = {
  id?: string;
  title: string;
  imageUrl?: string;
  description?: string;
  startTimestamp: number;
  endTimestamp?: number;
  address?: Address;
  location?: GeoLocationInput;
  ticketLink?: string;
  tags?: string[];
  contact?: Contact;
  venue?: VenueStub;
  hosts?: VendorStub[];
};

export type UpdateEventInput = {
  id: string;
  title?: string;
  imageUrl?: string;
  description?: string;
  startTimestamp?: number;
  endTimestamp?: number;
  sponsorStatus?: SponsorStatus;
  address?: UpdateAddress;
  ticketLink?: string;
  tags?: string[];
  location?: GeoLocation;
  contact?: Contact;
  venue?: VenueStub;
  hosts?: VendorStub[];
};

export type GetObjectInput = {
  id: string;
};

export type DeleteObjectInput = {
  id: string;
};

export type CreateObjectInput = {
  id?: string;
};

export type OwnedObject = {
  ownerId: string;
};

export type UpdateObjectInput = {
  id: string;
  startTimestamp?: number;
};

export type WhereStatement = {
  field: string;
  operation: WhereFilterOp;
  value: any;
};

export type OrStatement = {
  whereFilters: WhereStatement[];
};

export type QueryInput = {
  andStatements: OrStatement[];
};

export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
}

export type SortField = {
  field: string;
  direction: SortDirection;
};

export type ListQueryInput = {
  queryInput?: QueryInput;
  sortBy?: SortField;
  limit?: number;
  nextToken?: QueryDocumentSnapshot;
};

export type ListNearByQueryInput = {
  queryInput?: QueryInput;
  center: GeoLocationInput;
  radiusInKiloMeter: number;
};

export type ModelResponse<ObjectType> = {
  items: Array<ObjectType>;
  nextToken?: QueryDocumentSnapshot;
};

export type XUser = {
  id: string;
  isAdmin: boolean;
  gender?: string;
  birthday?: string;
  isIdVerified?: boolean;
  locations?: string[];
  identityInfo?: {
    idOrPassportImage: string;
    selfie: string;
    isHuman: string;
  };
  createdAt: number;
  ownerId: string;
};

export type CreateXUserInput = {
  id: string;
  gender?: string;
  birthday?: string;
};

export type UpdateXUserInput = {
  id: string;
  isAdmin?: boolean;
  gender?: string;
  birthday?: string;
  isIdVerified?: boolean;
  locations?: string[];
  identityInfo?: {
    idOrPassportImage?: string;
    selfie?: string;
  };
};

export type UpdateUserInput = {
  name?: Name;
  photoURL?: string;
  email?: string;
  password?: string;
};

export type Name = {
  firstName: string;
  lastName: string;
  middleName?: string;
};

export type SocialMedia = {
  website?: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
  tikTok?: string;
  twitter?: string;
  telegram?: string;
  soundCloud?: string;
  spotify?: string;
  appleMusic?: string;
  whatsapp?: string;
  onlyFans?: string;
};

export type Vendor = {
  id: string;
  username: string;
  displayName: string;
  headline: string;
  imageUrl?: string;
  description?: string;
  sponsorStatus: SponsorStatus;
  location?: GeoLocation;
  address?: Address;
  contact?: Contact;
  audience?: string[];
  types: VendorType[];
  socialMedia?: SocialMedia;
  createdAt: number;
  ownerId: string;
};

export type CreateVendorInput = {
  id?: string;
  username: string;
  displayName: string;
  headline: string;
  types: VendorType[];
  imageUrl?: string;
  description?: string;
  address?: Address;
  location?: GeoLocation;
  contact?: Contact;
  audience?: string[];
  socialMedia?: SocialMedia;
};

export type UpdateVendorInput = {
  id: string;
  username?: string;
  displayName?: string;
  headline?: string;
  types?: VendorType[];
  imageUrl?: string;
  description?: string;
  sponsorStatus?: SponsorStatus;
  location?: GeoLocation;
  address?: UpdateAddress;
  contact?: Contact;
  audience?: string[];
  socialMedia?: SocialMedia;
};

export type ChatMember = {
  memberId: string;
  type: ChatMemberType;
  displayName: string;
  lastReadTime: number;
  lastWriteTime: number;
};

export type CreateChatMember = {
  memberId: string;
  type: ChatMemberType;
};

export type UpdateChatMember = {
  lastReadTime?: number;
  lastWriteTime?: number;
};

export type Chat = {
  id: string;
  title: string;
  client: ChatMember;
  provider: ChatMember;
  // only used for security purposes and maintained by backend
  authorizedUid?: string[];
  // timestamp of the last update or new message in this chat
  updatedAt: number;
  createdAt: number;
  ownerId: string;
};

export enum ChatMemberType {
  User = "USER",
  Vendor = "VENDOR",
}

export type CreateChatInput = {
  id?: string;
  title: string;
  client: CreateChatMember;
  provider: CreateChatMember;
};

export type UpdateChatInput = {
  id: string;
  title?: string;
  client?: UpdateChatMember;
  provider?: UpdateChatMember;
};

export type Message = {
  id: string;
  text: string;
  senderId: string;
  isDeleted: boolean;
  createdAt: number;
};

export type CreateMessageInput = {
  id?: string;
  text: string;
  senderId: string;
};

export type UpdateMessageInput = {
  id: string;
  text?: string;
  isDeleted?: boolean;
};

export type UploadFileInput = {
  directoryName: string;
  fileName: string;
  file: File | Uint8Array;
  metadata?: UploadMetadata;
};
